import React, { useEffect, useState } from 'react';
import Products from '../Components/products/list/product-list';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { IMAGE_URL } from '../common/baseUrl';
//import '../assets/css/category.css';
import banner1 from '../assets/images/banner/furniture-banner-1.jpg';
import banner2 from '../assets/images/banner/Web-Banner-1-New.jpg';
import { useDispatch, useSelector } from "react-redux"
import ProductSkeletonList from '../Components/products/list/skeleton-list';
import { CUSTOM_TYPE_URL } from '../common/baseUrl'
import { selectedType, showWebFooter, hideWebFooter } from '../store/reducers/appReducer'
import { resetCustomGroupItems, saveCustomGroupItems } from '../store/reducers/productReducer'
import useInfiniteScroll from "../utils/useInfiniteScroll";
let pageNo = 1;

const Category = () => {
  const { type_id } = useParams();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchData);
  const [allLoaded, setAllLoaded] = useState(false);
  const { outlet_id, sidebarData, subTypeData, isLoaded, typeId, subTypeInfo } = useSelector((state) => state.app);
  const { customGroupItems } = useSelector((state) => state.product);

  const navigate = useNavigate();

  useEffect(() => {
    if (subTypeData.length < 1 && isLoaded) {
      pageNo = 1;
      window.scrollTo(0, 0)
      dispatch(resetCustomGroupItems());
      setAllLoaded(false);
      fetchData();
    }
  }, [type_id]);


  useEffect(() => {
    if (type_id > 11 && !isLoaded) {
      console.log('ppp');
      pageNo = 1;
      window.scrollTo(0, 0)
      dispatch(resetCustomGroupItems());
      setAllLoaded(false);
      fetchData();
    } else {
      let typeInfo = {
        type_id: type_id,
        subtype_id: 9900,
        filterSubType: true
      }
      dispatch(selectedType(typeInfo));
    }
  }, [isLoaded]);

  function fetchData() {
    let itemURL = CUSTOM_TYPE_URL + type_id + '/' + outlet_id + '/' + pageNo;
    axios.get(itemURL)
      .then((response) => {
        setTimeout(() => {
          dispatch(saveCustomGroupItems(response.data.offerdProductInfo));
          if (response.data.offerdProductInfo.length === response.data.record_per_fatch) {
            dispatch(hideWebFooter());
            setIsFetching(false);
          } else {
            dispatch(showWebFooter());
            setAllLoaded(true);
          }
        }, 1000);
        pageNo = pageNo + 1;
      })
      .catch((error) => {
        //alert(error);
        setIsFetching(false);
      })
  };

  const handleCardClick = (subtype_id) => {
    navigate(`/subcategory/${typeId}/${subtype_id}`);
  };
  
  return (
    <>
      {subTypeData.length < 1 ?
        <>
          <Products productList={customGroupItems} />
          {customGroupItems.length < 1 && <ProductSkeletonList />}
          {isFetching && !allLoaded &&
            <Box sx={{ flexDirection: "column", display: 'flex', justifyContent: 'center', minHeight: "15vh", alignItems: "center" }}>
              <CircularProgress />
              <Typography sx={{ py: 1, color: "blue" }}>Loading...</Typography>
            </Box>
          }
        </>
        :
        <Grid container spacing={2} className="category-main-page">
          <Grid item xs={12} sm={6} className="banner-grid">
            <Box
              sx={{
                background: ` url(${banner1})`,
                display: { xs: 'none', md: 'block' },
              }}
              className="banner-section"
            ></Box>
          </Grid>
          <Grid item xs={12} md={6} className="banner-grid">
            <Box
              sx={{ background: ` url(${banner2})` }}
              className="banner-section"
            ></Box>
          </Grid>

          {subTypeData.map((subCat, i) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              xl={2}
              // key={product.product_id}
              onClick={() => handleCardClick(subCat.subtype_id)}
              sx={{ cursor: 'pointer' }}
              key={i}
            >
              <Card className='card-sec'>
                <CardMedia
                  component="img"
                  image={IMAGE_URL + 'banner/' + subCat.mobile_banner}
                  alt="no-category-img-found"
                />
                <Typography className="cat-view-more-btn-text">
                  View Items{' '}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      }
    </>
  );
};

export default Category;
