import { Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import axios from 'axios';
import '../../assets/css/my-order/my-order.css';
import { useState, useEffect } from 'react';
import { MY_ORDER_URL, ORDER_DETAILS_URL } from '../../common/baseUrl';
import { saveMyOrderInfo, resetMyOrderInfo, saveOrderDetails, resetOrderDetails } from '../../store/reducers/authReducer'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, } from 'react-router-dom';
import moment from "moment";

const OrderInfoMobile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLogin, customer_id, phoneNo, myOrderInfo, orderDetails } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(true);

    const getInfo = (id) => {
        const selected = myOrderInfo.find((item) => item.r_order_id === id);
        setLoading(true);
        loadOrderDetails(selected);
    };

    useEffect(() => {
        if (!isLogin) {
            navigate('/');
        }
        window.scrollTo(0, 0)
        dispatch(resetMyOrderInfo());
        setTimeout(() => {
            const url = MY_ORDER_URL + customer_id;
            console.log(url);
            axios.get(url)
                .then((res) => {
                    console.log(res.data.orderInfo);
                    dispatch(saveMyOrderInfo(res.data.orderInfo));
                    setTimeout(() => {
                        setIsFetching(false);
                    }, 1000);

                })
                .catch((error) => {
                    setLoading(false);
                    setIsFetching(false);
                })
        }, 100);
    }, []);

    const loadOrderDetails = (order) => {
        window.scrollTo(0, 0)
        dispatch(resetOrderDetails());
        let url = ORDER_DETAILS_URL + order.ref_no;
        fetch(url)
            .then((response) => response.json())
            .then((responseJson) => {
                dispatch(saveOrderDetails(responseJson.orderDetails));
                navigate('/my-order-details', { state: order });
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <>
            {isFetching ?
                <Box sx={{ flexDirection: "column", display: 'flex', justifyContent: 'center', minHeight: "15vh", alignItems: "center" }}>
                    <CircularProgress />
                    <Typography sx={{ py: 1, color: "blue" }}>Loading...</Typography>
                </Box>
                :
                <Box className="section">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4} lg={3}>
                            {myOrderInfo.map((order) => (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Box
                                            className="order-info-box"
                                            onClick={() => getInfo(order.r_order_id)}
                                        >
                                            <Typography className="order-info">
                                                <span className="info-name">Order Id: </span>
                                                {order.r_order_id}
                                            </Typography>
                                            <Typography className="order-info">
                                                <span className="info-name">Net payable: </span>
                                                {order.total_price}
                                            </Typography>
                                            <Typography className="order-info">
                                                <span className="info-name">Date: </span>
                                                {moment(order.order_date).format("DD/MM/YYYY")}
                                            </Typography>
                                            <Typography className="order-info">
                                                <span className="info-name">Status: </span>
                                                {order.status}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            }
        </>
    );
};

export default OrderInfoMobile;