import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import Products from '../Components/products/list/product-list';
import ProductSkeletonList from '../Components/products/list/skeleton-list';
import { Box, Typography } from '@mui/material';
import ModalContent from '../Components/products/modal/modal-content';
import { PRODUCTINFO_URL } from '../common/baseUrl'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import GrainIcon from '@mui/icons-material/Grain';
import { useDispatch, useSelector } from "react-redux"
import { resetItemsInfo, saveItemsInfo } from '../store/reducers/productReducer'

const SingleProductDetailsMain = () => {
    const dispatch = useDispatch();
    const { outlet_id, popularItemsInfo } = useSelector((state) => state.app);
    const { singleProductInfo, relatedProductInfo } = useSelector((state) => state.product);
    const [isFetching, setIsFetching] = useState(true);
    const { product_id } = useParams();
    
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(resetItemsInfo());
        fetchData();
    }, []);
    
    function fetchData() {
        let itemURL = PRODUCTINFO_URL + product_id + '/' + outlet_id;
       
        axios.get(itemURL)
            .then((response) => {
                dispatch(saveItemsInfo(response.data));
                setTimeout(() => {
                    setIsFetching(false);
                }, 500);
            })
            .catch((error) => {
                console.log(error);
                //setIsFetching(false);
            })
    };

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    return (
        <Box>
            {isFetching ?
                <ProductSkeletonList />
                :
                <>
                    <Box>
                        <ModalContent data={singleProductInfo} />
                    </Box>
                    <div
                        role="presentation"
                        // onClick={handleClick}
                        className="breadcrumbs-container"
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                className="current-item"
                            >
                                <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Related Product
                            </Typography>
                        </Breadcrumbs>
                    </div>
                    {relatedProductInfo.length > 0 && <Products productList={relatedProductInfo} />}
                </>
            }
        </Box>
    );
};

export default SingleProductDetailsMain;