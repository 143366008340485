import { Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import axios from 'axios';
import '../../assets/css/my-order/my-order.css';
import OrderInfo from './order-info';
import { useState, useEffect } from 'react';
import { MY_ORDER_URL, ORDER_DETAILS_URL } from '../../common/baseUrl';
import { saveMyOrderInfo, resetMyOrderInfo, saveOrderDetails, resetOrderDetails } from '../../store/reducers/authReducer'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom';
import CartItems from './order-details';
import OrderDetails from './order-details';


const MyOrderInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogin,customer_id, phoneNo, myOrderInfo, orderDetails } = useSelector((state) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [activeId, setActiveId] = useState('');
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [order, setOrder] = useState([]);
  const [subTotal, setSubTotal] = useState('');
  const [deliveryCharge, setDeliveryCharge] = useState('');
  const [payable, setPayable] = useState('');

  const getInfo = (id) => {
    const selected = myOrderInfo.find((item) => item.r_order_id === id);
    setLoading(true);
    loadOrderDetails(selected);
    setSelectedOrder(selected);
    setActiveId(id);
  };

  useEffect(() => {
    if (!isLogin) {
      navigate('/');
    }
    window.scrollTo(0, 0)
    dispatch(resetMyOrderInfo());
    setTimeout(() => {
      const url = MY_ORDER_URL + customer_id;
      axios.get(url)
        .then((res) => {         
          dispatch(saveMyOrderInfo(res.data.orderInfo));
          if (res.data.orderInfo.length > 0) {
            loadOrderDetails(res.data.orderInfo[0]);
          }
          setTimeout(() => {
            setIsFetching(false);
          }, 1000);

        })
        .catch((error) => {
          setLoading(false);
          setIsFetching(false);
        })
    }, 100);
  }, []);

  const loadOrderDetails = (order) => {
    window.scrollTo(0, 0)
    setSelectedOrder(order);
    dispatch(resetOrderDetails());
    setActiveId(order.r_order_id);
    let url = ORDER_DETAILS_URL + order.ref_no;
    fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(saveOrderDetails(responseJson.orderDetails));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      {isFetching ?
        <Box sx={{ flexDirection: "column", display: 'flex', justifyContent: 'center', minHeight: "15vh", alignItems: "center" }}>
          <CircularProgress />
          <Typography sx={{ py: 1, color: "blue" }}>Loading...</Typography>
        </Box>
        :
        <Box className="section">
          <Typography className="order-page-title" variant="h3" sx={{ display: { xs: 'none', sm: 'block' } }}>
            Order details of ID : #{activeId}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} lg={3}>
              {myOrderInfo.map((order) => (
                <OrderInfo
                  order={order}
                  getInfo={getInfo}
                  key={order.r_order_id}
                  activeId={activeId}
                />
              ))}
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              lg={9}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              {loading ?
                <Box sx={{ flexDirection: "column", display: 'flex', justifyContent: 'center', minHeight: "20vh", alignItems: "center" }}>
                  <CircularProgress />
                  <Typography sx={{ py: 1, color: "blue" }}>Loading...</Typography>
                </Box>
                :
                <OrderDetails selectedOrder={selectedOrder} />
              }
            </Grid>

          </Grid>
        </Box>
      }
    </>
  );
};

export default MyOrderInfo;
