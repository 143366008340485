import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import '../../assets/css/features/login-register-form.css';
import { SEND_OTP_URL, REGISTER_CUSTOMER_URL, API_KEY } from '../../common/baseUrl';
import { saveUserInfo } from '../../store/reducers/authReducer'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom';
let RandomNumber = 1;

const LoginForm = () => {
  const { verifyUsingOtp } = useSelector((state) => state.app);
  const { isFormChkOut } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [mobileNo, setMobileNo] = useState('');
  const [tempOTP, setTempOTP] = useState('');
  const [error, setError] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [userInfo, setUserInfo] = useState({
    customer_id: '',
    userPhone: '',
    userName: '',
    userAddress: '',
    userAltNumber: '',
    access_key: '',
    userOtp: '',
  });

  const myUserInfo = {
    userName: 'Imdad',
    userAddress: 'GEC CTG ',
    userAltNumber: '0168294634',
  };

  const getInputValue = (e) => {
    const { name, value } = e.target;
    setMobileNo(value);
    if (value.length < 11 || value.length > 11) {
      setError(true);
    } else setError(false);
  };

  // const handleSubmit = (e) => {
  //   setUserInfo((prev) => ({
  //     ...prev,
  //     userName: 'Imdad',
  //     userAddress: 'GEC CTG ',
  //     userAltNumber: '0168294634',
  //   }));
  // };


  const handleLogin = (e) => {
    e.preventDefault();

    RandomNumber = Math.floor(Math.random() * 8999 + 1000);
    setTempOTP(RandomNumber);

    const url = SEND_OTP_URL + mobileNo + '/' + RandomNumber + '/' + API_KEY;

    checkInternetConnection();
  };

  const checkInternetConnection = () => {
    const isConnectionAvailable = window.navigator.onLine;
    send_sms(isConnectionAvailable);
  }

  const send_sms = (isConnectionAvailable) => {

    if (isConnectionAvailable) {
      fetch(SEND_OTP_URL,
        {
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            {
              mobile: mobileNo,
              pass: RandomNumber,
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
          setShowUserInfo(true);
          setUserInfo((prev) => ({
            ...prev,
            customer_id: responseJson.customer_id,
            userPhone: responseJson.contact_no1,
            userName: responseJson.cust_name,
            userAddress: responseJson.address,
            userAltNumber: responseJson.contact_no2,
            access_key: responseJson.access_key,
          }));
        })
        .catch((error) => {
          console.log(error);
          alert("Hold on! Somethig went worng Try again later", [
            {
              text: "OK",
              onPress: () => null,
              style: "OK"
            },
          ]);
        });
    } else {
      alert("Hold on! Internet Connection Lost", [
        {
          text: "OK",
          onPress: () => null,
          style: "OK"
        },
      ]);
    }
  }

  const getUserinfo = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isConnectionAvailable = window.navigator.onLine;
    if (userInfo.userOtp == tempOTP || verifyUsingOtp == '0') {
      if (isConnectionAvailable) {
        fetch(REGISTER_CUSTOMER_URL,
          {
            method: 'POST',
            headers:
            {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(
              {
                customer_id: userInfo.customer_id,
                name: userInfo.userName,
                address: userInfo.userAddress,
                mobile: mobileNo,
                altContact: userInfo.userAltNumber,
                accessKey: '8jdfjd88743jhg',
                deviceKey: API_KEY,
              })
          })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson);
            setUserInfo((prev) => ({
              ...prev,
              customer_id: responseJson.customer_id
            }));
            if (responseJson.isDataSaved == 'No') {
              if (responseJson.hasErrorMsg1 == 'Yes') {
                alert("Hold on!", responseJson.contact_no1Error, [
                  {
                    text: "OK",
                    onPress: () => null,
                    style: "OK"
                  },
                ]);

              }

              if (responseJson.hasErrorMsg2 == 'Yes') {
                alert("Hold on!", responseJson.contact_no2Error, [
                  {
                    text: "OK",
                    onPress: () => null,
                    style: "OK"
                  },
                ]);
              }
            } else {
              setTimeout(() => {
                storeData();
              }, 200);
            }

          }).catch((error) => {
            console.log(error);
            alert("Hold on! Somethig went worng Try again later", [
              {
                text: "OK",
                onPress: () => null,
                style: "OK"
              },
            ]);
          });

      } else {
        alert("Hold on! Internet Connection Lost", [
          {
            text: "OK",
            onPress: () => null,
            style: "OK"
          },
        ]);
      }
    } else {
      alert("Hold on! Please Enter Valid OTP", [
        {
          text: "OK",
          onPress: () => null,
          style: "OK"
        },
      ]);
    }


    // setIsLogin(true);
    // setLoading(false);
  };

  const navigate = useNavigate();

  const storeData = () => {

    let customerInfo = {
      customer_id: userInfo.customer_id,
      name: userInfo.userName,
      address: userInfo.userAddress,
      mobile: mobileNo,
      altContact: userInfo.userAltNumber,
      //accessKey: this.state.access_key,
    }

    if (isFormChkOut) {
      navigate('/checkout');
    } else {
      navigate('/');
    }

    dispatch(saveUserInfo(customerInfo));
    localStorage.setItem("@user", JSON.stringify(customerInfo));
  };


  return (
    <Box className="login-register-sec">
      {!showUserInfo ? (
        <Box
          className="login-info-box"
          component="form"
          onSubmit={handleLogin}
        >
          <TextField
            error={error}
            id="outlined-basic"
            label="Enter your mobile number"
            variant="outlined"
            className="otp-input"
            placeholder="01710000000"
            onChange={getInputValue}
            type="number"
            name="contactNo"
            helperText={`${error ? 'Enter a valid phone number (11 digit)' : ''
              }`}
          />
          <Button variant="contained" className="otp-sent-btn" type="submit">
            Login with OTP
          </Button>
        </Box>
      ) : (
        <Box
          className=" login-info-box user-info-box"
          component="form"
          onSubmit={handleSubmit}
        >
          <Typography variant="body1" className="user-info-title">
            {' '}
            Your Information{' '}
          </Typography>
          <TextField
            className="input-common user-input"
            id="outlined-basic"
            label="Phone number"
            variant="outlined"
            name="userPhone"
            value={userInfo.userPhone}
            defaultValue={mobileNo}
            disabled={true}
          />
          <TextField
            className="input-common user-input"
            id="outlined-basic"
            label="Enter name"
            variant="outlined"
            onChange={getUserinfo}
            value={userInfo.userName}
            name="userName"
          />
          <TextField
            className="input-common user-text-area"
            id="outlined-multiline-static"
            label="Enter address"
            multiline
            rows={4}
            onChange={getUserinfo}
            name="userAddress"
            value={userInfo.userAddress}
          />
          <TextField
            className="input-common user-input"
            id="outlined-basic"
            label="Alternative Number"
            variant="outlined"
            placeholder="0170000000"
            onChange={getUserinfo}
            name="userAltNumber"
            value={userInfo.userAltNumber}
          />
          {verifyUsingOtp == '1' && (
            <TextField
              className="input-common input-otp"
              id="outlined-basic"
              label="Enter OTP"
              variant="outlined"
              onChange={getUserinfo}
              name="userOtp"
              value={userInfo.userOtp}
            />
          )}

          <Button variant="contained" className="login-btn" type="submit">
            {' '}
            Login
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default LoginForm;
