import { Box, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { IMAGE_URL } from '../../../common/baseUrl.js';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Button from '@mui/material/Button';
import CounterInputBox from '../counter/counter-input-box';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useCart } from '../../../contexts/cart/use-cart';
import { useNavigate } from "react-router-dom";
import browserHistory from './history';

const ModalContent = ({ data }) => {
    const navigate = useNavigate();
    const { addItem, removeItem, getItem, isInCart } = useCart();
    let [tpPrice, setTpPrice] = useState(0);
    let [less, setLess] = useState(0);
    let [lessType, setLessType] = useState('');

    if (parseFloat(data.max_retail_price) > 0 && parseFloat(data.less_by_supplier) > 0) {
        tpPrice = data.max_retail_price;
        less = data.less_ws_apps;
        lessType = data.less_type_ws_apps;
    } else {
        tpPrice = data.wholesale_price;
        less = data.off;
        lessType = data.off_type;
    }

    let URL1 = IMAGE_URL + data.type_id + '/' + data.product_pic1;
    let gallery = [];
    gallery.push({
        url: URL1,
    });

    if (data.product_pic2 != '') {
        let URL2 = IMAGE_URL + data.type_id + '/' + data.product_pic2;
        gallery.push({
            url: URL2,
        });
    }

    if (data.product_pic3 != '') {
        let URL3 = IMAGE_URL + data.type_id + '/' + data.product_pic3;
        gallery.push({
            url: URL3,
        });
    }

    if (data.product_pic4 != '') {
        let URL4 = IMAGE_URL + data.type_id + '/' + data.product_pic4;
        gallery.push({
            url: URL4,
        });
    }

    useEffect(() => {
        //const regex = /(<([^>]+)>)/ig; or 
        const regex = /(<([^>]+)>)/gi;
        // const desBeng = (data.product_desc_beng).replace(regex, "");
        // const desEng = data.product_desc_eng.replace(regex, "");

    }, []);

    const [block, setBlock] = useState(false);
    const [isBackButtonClicked, setBackbuttonPress] = useState(false)
    useEffect(() => {
        // window.onpopstate = e => {
        //     alert('7');
        //     e.stopPropagation();

        //     if (block) {
        //         // Block navigation and register a callback that
        //         // fires when a navigation attempt is blocked.
        //         browserHistory.block((tx) => {
        //             // Navigation was blocked! Let's show a confirmation dialog
        //             // so the user can decide if they actually want to navigate
        //             // away and discard changes they've made in the current page.
        //             tx.retry();
        //         });
        //     }

        // }

        //window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);      
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isBackButtonClicked) {

            if (window.confirm("Do you want to go to Test Listing")) {
                setBackbuttonPress(false)

            } else {
                //window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        }
    }

    const children = gallery.slice(0, 6).map((item, index) => (
        <Box>
            <img src={item.url} key={index} alt={data.product_pic1} />
        </Box>
    ));

    const handleAddClick = (e) => {
        e.stopPropagation();
        addItem(data);
    };

    const handleRemoveClick = (e) => {
        e.stopPropagation();
        removeItem(data);
    };


    return (
        <div className="modal-grid-inner-main">
            {/*  <Button className="sm-screen-btn">
        <CloseIcon
          sx={{
            boxShadow: 3,
            fontSize: 26,
            p: 1,
            borderRadius: '50%',
            backgroundColor: 'white',
            color: 'black',
            display: { xs: 'none', md: 'block' },
          }}
        />
      </Button> */}
            <Grid container>
                <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
                    <Carousel className="cw-carousel" autoPlay={false} showArrows={true}>
                        {children}
                    </Carousel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box
                        className="modal-content-right"
                        sx={{ marginTop: { xs: 0, sm: '3em' } }}
                    >
                        <Box className="modal-title-content">
                            <Typography className="product-name">
                                {data.product_title_eng}
                            </Typography>
                            <Typography className="product-name">
                                {data.product_title_beng}
                            </Typography>
                            <Typography className="product-size">{data.size}</Typography>
                            <Typography className="product-size">Product Code : {data.product_code}</Typography>
                        </Box>
                        <Box className="add-to-cart-sec">
                            {data.is_available == 1 ? (
                                <Box className="price-sec">
                                    <Typography className="discount-price">
                                        ৳ {data.ws_price_aftrer_off}
                                    </Typography>
                                    {parseFloat(less) > 0 && parseFloat(tpPrice) > 0 && (
                                        <Typography className="actual-price">৳ {tpPrice}</Typography>
                                    )}
                                </Box>
                            )
                                :
                                (null)
                            }
                            {!isInCart(data.product_id) ? (
                                <>
                                    {data.is_available == 1 ? (
                                        // <Box className="modal-add-to-cart" onClick={handleAddClick}>
                                        //     <Button className="before-add-btn">
                                        //         <ShoppingBasketIcon />
                                        //         Add to Bag
                                        //     </Button>
                                        // </Box>
                                        <CounterInputBox
                                            maxAllowed={data.max_allowed}
                                            value={0}
                                            onDecrement={handleRemoveClick}
                                            onIncrement={handleAddClick}
                                            symbol={data.symbol}
                                        />
                                    )
                                        :
                                        (null)}

                                </>
                            ) : (
                                <CounterInputBox
                                    maxAllowed={data.max_allowed}
                                    value={getItem(data.product_id).quantity}
                                    onDecrement={handleRemoveClick}
                                    onIncrement={handleAddClick}
                                    symbol={data.symbol}
                                />
                            )}
                        </Box>
                        <Box className="description-sec">
                            <Typography className="des-text">
                                {(data.product_desc_beng).replace(/(<([^>]+)>)/ig, '')}
                            </Typography>
                            <br />
                            <Typography className="des-text">
                                {(data.product_desc_eng).replace(/(<([^>]+)>)/ig, '')}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default ModalContent;
