import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Divider,
} from '@mui/material';
import React from 'react';
import { useState, useEffect } from 'react';
import '../assets/css/features/checkout.css';
import '../assets/css/features/place-order.css';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { useCart } from '../contexts/cart/use-cart';
import { useDispatch, useSelector } from "react-redux"
import { PLACE_ORDER_URL, API_KEY } from '../common/baseUrl'
import bakas from '../assets/images/app-icon/bkash.png';
import nagad from '../assets/images/app-icon/nagad.png';
import visa from '../assets/images/app-icon/visa_logo.png';
import cash from '../assets/images/app-icon/Cash-on-Delivery.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { clickOnFooterChkOutBtn } from '../store/reducers/authReducer'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items, calculatePrice, clearCart, cartItemsCount, calculateDeliveryCost } = useCart();
  const { outlet_id, deliveryChargeMax, deliveryChargeMin, mimimunAmount, deliveryChargeMSG, deliveryNotice } = useSelector((state) => state.app);
  const { isLogin, phoneNo, custName, custAdress, alternativeMobileNo } = useSelector((state) => state.auth);
  const { isDesktop, isTablet, isMobile } = useSelector((state) => state.status);
  const [paymentMethod, setPaymentMethod] = React.useState('cash');
  const [userRemarks, setUserRemarks] = useState('');

  const subTotal = calculatePrice();
  const shippingCost = calculateDeliveryCost();

  let deliveryCharge = 0;
  if (parseFloat(subTotal) < parseFloat(mimimunAmount)) {
    deliveryCharge = (parseFloat(shippingCost) + parseFloat(deliveryChargeMax)).toFixed(2);
  } else {
    deliveryCharge = (parseFloat(shippingCost) + parseFloat(deliveryChargeMin)).toFixed(2);
  }
  const Payable = (parseFloat(subTotal) + parseFloat(deliveryCharge)).toFixed(2);
  var today = new Date();
  let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  useEffect(() => {

    if (!isLogin && !isMobile) {
      navigate('/');
    }

    if (!isLogin && isMobile) {
      window.scrollTo(0, 0);
      dispatch(clickOnFooterChkOutBtn());
      navigate('/login');
    }

  }, [isLogin]);

  const handleChange = (e) => {
    const pMethod = e.target.value;
    setPaymentMethod(pMethod);
  };

  const handleUserRemarks = (e) => {
    const getRemarks = e.target.value;
    setUserRemarks(getRemarks);
  };

  const handlePlaceOrder = async () => {
    const isConnectionAvailable = window.navigator.onLine;
    if (isConnectionAvailable && cartItemsCount > 0 && isLogin) {
      handleClickOpenDialog();
      console.log(PLACE_ORDER_URL);
      fetch(PLACE_ORDER_URL,
        {
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            {
              outlet_id: outlet_id,
              cartItem: items,
              subTotal: subTotal,
              deliveryCharge: deliveryCharge,
              mobile: phoneNo,
              payment_type: paymentMethod,
              remarks: userRemarks,
              accessKey: '8jdfjd88743jhg',
              deviceKey: API_KEY,
              deliveryDate: date,
              address: custAdress,
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          handleClose();
          clearCart();
          if (isMobile) {
            navigate('/my-order-info');
          } else {
            navigate('/my-order');
          }
        }).catch((error) => {
          handleClickOpen();
          console.log(error);
        });
    } else {
      handleClickOpen();
    }

  };

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
  };

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', paddingTop: '0px' }}>
      <Box className="checkout-sec">
        <Typography className="general-notice">
          {' '}
          {deliveryNotice}
        </Typography>
        <Box className=" checkout-info-box ">
          <Box className="user-title-sec">
            <Typography variant="body1" className="user-info-title">
              <ContactPhoneIcon />
              <span>Cotnact details</span>
            </Typography>
            <Typography className="edit-user-info">
              Edit <ModeEditOutlineIcon fontSize="small" />
            </Typography>
          </Box>
          <Box className="user-info-details">
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {' '}
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{custName}</span>
            </Typography>
            <Typography variant="body2">{custAdress}</Typography>
            <Typography variant="body2">
              <span>{phoneNo}</span>
            </Typography>
            <Typography variant="body2">
              <span>{alternativeMobileNo}</span>
            </Typography>
          </Box>
          <Box className="sub-total-sec">
            <Box>
              <Typography className="sub-total-text">Subtotal Amount</Typography>
              <Typography className="sub-total-text">Delivery Charge</Typography>
              <Typography className="sub-total-text">Less</Typography>
            </Box>
            <Box>
              <Typography className="sub-total-text">{subTotal}</Typography>
              <Typography className="sub-total-text">{deliveryCharge}</Typography>
              <Typography className="sub-total-text">0.00</Typography>
            </Box>
          </Box>
          <Divider />
          <Box className="total-sec">
            <Typography className="total-sec-text">Total Amount</Typography>
            <Typography className="total-sec-text"> ৳ {Payable}</Typography>
          </Box>
          <Box>
            <TextField
              onChange={handleUserRemarks}
              className="input-common user-text-area"
              id="outlined-multiline-static"
              label="User remarsk"
              multiline
              fullWidth
              rows={4}
              name="userRemarks"
            />
          </Box>
          <Box className="payment-sec">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Payment option</span>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={paymentMethod}
                onChange={handleChange}
              >
                <Box>
                  <FormControlLabel
                    value="cash"
                    control={<Radio />}
                    label="Cash on delivery"
                    type="input"
                    name="cash"
                  />
                  <img src={cash} alt="no-img" className="common-img cash" />
                </Box>
                <Box>
                  <FormControlLabel
                    value="card"
                    control={<Radio />}
                    label="Credit/Devit card"
                  />
                  <img src={visa} alt="no-img" className="common-img visa" />
                </Box>
                <Box className="payment-icon">
                  <FormControlLabel
                    value="bkash"
                    control={<Radio />}
                    label="Bkash"
                  />
                  <img src={bakas} alt="no-img" className="common-img" />
                </Box>
                <Box className="payment-icon">
                  <FormControlLabel
                    value="nagad"
                    control={<Radio />}
                    label="Nagad"
                  />
                  <img src={nagad} alt="no-img" className="common-img nagad" />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          {!isMobile ?
            <Button variant="contained" className="checkout-cw-btn" type="submit" onClick={handlePlaceOrder}>
              {' '}
              Place Order
            </Button>
            :
            <Box className="place-order-sec">
              <Link to="/" className="home-btn-link">
                <Button variant="contained" className="place-order-common-btn">
                  Home
                </Button>
              </Link>
              <Button
                variant="contained"
                className="place-order place-order-common-btn"
                type="submit"
                onClick={handlePlaceOrder}
              >
                Place Order
              </Button>
              <Link to="/cart-page" className="cart-back-link">
                <Button variant="contained" className="place-order-common-btn">
                  View Cart
                </Button>
              </Link>
            </Box>
          }
        </Box>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Hold on!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Internet Connection Lost or No Item in Cart.....
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Hold on!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please Wait.....
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Checkout;
